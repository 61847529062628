import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/packages/web/assets/buddyproject_logo.svg");
;
import(/* webpackMode: "eager" */ "/app/packages/web/assets/underconstruction.webp");
;
import(/* webpackMode: "eager" */ "/app/packages/web/assets/yesbot-buddy-project.webp");
;
import(/* webpackMode: "eager", webpackExports: ["NavLink"] */ "/app/packages/web/src/ui/common/navigation/nav-link/nav-link.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["MobileNavigation"] */ "/app/packages/web/src/ui/common/navigation/navigations/mobile-navigation.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Profile"] */ "/app/packages/web/src/ui/common/navigation/profile/profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BackLink"] */ "/app/packages/web/src/ui/misc/back-link.tsx");
